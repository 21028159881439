<template>
    <section class="forms">
        <div class="page-header">
            <h3 class="page-title">
                Send Notification
            </h3>
            <button type="button" class="btn btn-info believe-btn" @click="goBack()">
                <i class="mdi mdi-keyboard-backspace"></i> Back</button>

            <!-- <router-link class="btn btn-primary believe-btn" :to="`/hypnosis/list/${current_category_id}`">
            <i class="mdi mdi-keyboard-backspace"></i> Back</router-link> -->
            <!-- <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">hypn</a></li>
          <li class="breadcrumb-item active" aria-current="page">Basic</li>
        </ol>
      </nav> -->
        </div>
        <div class="row">
            <div class="col-md-8 m-auto d-flex align-items-stretch grid-margin">
                <div class="row flex-grow">
                    <div class="col-12 grid-margin">
                        <div class="card">
                            <div class="card-body">
                                <!-- <h4 class="card-title">Default form</h4>
                                <p class="card-description">
                                    Basic form layout
                                </p> -->
                                <b-form @submit.stop.prevent="onSubmit">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-form-group id="example-input-group-1" label="Title"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    v-model="$v.form.name.$model"
                                                    :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>

                                                <div v-if="$v.form.name.$error" class="invalid-feedback">
                                                    <div v-if="!$v.form.name.required">Title is required</div>
                                                    <div v-if="!$v.form.name.maxLength">Maximum limit 100
                                                        characters</div>
                                                </div>

                                            </b-form-group>


                                            <b-form-group id="example-input-group-1" label="Description"
                                                label-for="example-input-1">
                                                <b-form-textarea @keydown.native="test_keydown_handler"
                                                    id="descriptionTextarea" name="example-input-1"
                                                    v-model="$v.form.description.$model"
                                                    :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
                                                    aria-describedby="input-1-live-feedback"
                                                    placeholder="Enter something..." rows="3"
                                                    max-rows="6"></b-form-textarea>

                                                <div v-if="$v.form.description.$error" class="invalid-feedback">
                                                    <div v-if="!$v.form.description.required">Description is required
                                                    </div>
                                                </div>

                                            </b-form-group>


                                            <b-form-group id="example-input-group-1" label="Notification Time"
                                                label-for="example-start-timepicker">
                                                <b-form-timepicker id="example-start-timepicker" locale="en"
                                                    reset-button v-model="$v.form.start_time.$model"
                                                    :state="$v.form.start_time.$dirty ? !$v.form.start_time.$error : null"
                                                    class="mb-2"></b-form-timepicker>

                                                <div v-if="$v.form.start_time.$error" class="invalid-feedback">
                                                    <div v-if="!$v.form.start_time.required">Notification time is
                                                        required</div>
                                                </div>
                                            </b-form-group>


                                            <b-form-group id="example-group-datepicker" label="Notification Date"
                                                label-for="example-datepicker">
                                                <b-form-datepicker id="example-datepicker" v-bind:min="eventDate" v-model="$v.form.date.$model"
                                                    :state="$v.form.date.$dirty ? !$v.form.date.$error : null"
                                                    class="mb-2">
                                                </b-form-datepicker>
                                                <div v-if="$v.form.date.$error" class="invalid-feedback">
                                                    <div v-if="!$v.form.date.required">Notification date is
                                                        required</div>
                                                </div>
                                            </b-form-group>

                                        </div>
                                    </div>

                                    <b-button :disabled='is_loading' type="submit" variant="success"
                                        class="btn-block believe-btn">
                                        <i
                                            :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                        Submit
                                    </b-button>
                                </b-form>
                                <vue-snotify></vue-snotify>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { json } from 'body-parser';
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import api from "../../../config/api.js";
import Tag from "@/components/Tag.vue";
// import Goal from "@/components/Goal.vue";
// import Feeling from "@/components/Feeling.vue";
import AudioFile from "@/components/AudioFile.vue";
import { VueEditor } from "../../../../node_modules/vue2-editor";
// import simpleSnotifysuccess from '@/components/alerts/snotify/simpleSnotifysuccess'
export default {
    name: 'AudioAddModal',
    mixins: [validationMixin],
    data() {
        return {
            count: 0,
            msg: "",
            is_loading: false,
            form: {
                name: null,
                description: null,
                start_time: null,
                date: null
            }
        }
    },

    computed: {
        eventDate() {
            return new Date().toISOString().split('T')[0];
        },
    },

    components: {
        VueEditor,
        // Tag,
        // AudioFile,
        // Goal,
        // Feeling,
        // simpleSnotifysuccess
    },
    validations: {
        form: {
            name: {
                required,
                maxLength: maxLength(100)
            },
            description: {
                required,
            },
            start_time: {
                required,
            },
            date: {
                required,
            },
        }
    },

    mounted() {
        window.addEventListener("keypress", function (e) {
            if (e.code == "Enter") {
                e.preventDefault()
            }
        }.bind(this));
    },

    methods: {

        test_keydown_handler(event) {
            if (event.which === 13) {
                let txt = document.getElementById('descriptionTextarea');
                let indexOfChar = txt.value.slice(0, txt.selectionStart).length + 1
                txt.value = txt.value.slice(0, txt.selectionStart) + '\r\n' +
                txt.value.slice(txt.selectionStart, txt.value.length);
                this.setCaretPosition('descriptionTextarea',indexOfChar);
            }
        },

        setCaretPosition(elemId, caretPos) {
            var elem = document.getElementById(elemId);
            if (elem != null) {
                if (elem.createTextRange) {
                    var range = elem.createTextRange();
                    range.move('character', caretPos);
                    range.select();
                }
                else {
                    if (elem.selectionStart) {
                        elem.focus();
                        elem.setSelectionRange(caretPos, caretPos);
                    }
                    else
                        elem.focus();
                }
            }
        },

        goBack() {
            let prev_url = '/notifications/list';
            this.$router.push({ path: prev_url });
        },


        async onSubmit() {
            // console.log(this.tags.length);
            // console.log(this.$v.form.description.$error);
            // console.log(this.$v.form.author.$error);
            this.$v.form.$touch()

            if (this.$v.form.$anyError) {
                return false;
            }

            try {
                this.is_loading = true;
                let obj = this.$store.getters['getEditFormData'];
                let endpoint = "/admin/sendNotificationToAllUsers";

                const formData = new FormData();
                formData.append("title", this.form?.name);
                formData.append("body", this.form.description);
                formData.append("time", this.form.start_time);
                formData.append("date", this.form.date);

                let result = await api.post(endpoint, formData);
                const msg = await result.data.message;
                this.$store.dispatch('setMessage', { msg });
                // await this.$store.dispatch(`${obj.type}/all_after_submit`);
                let prev_url = `/notifications/list`;
                this.$router.push({ path: prev_url });
                this.is_loading = false;
            } catch (error) {
                console.log(error);
                // this.progress = 0;
                // let err = error.response.data.message;
                // this.$store.dispatch('setMessage', { err });
                // this.showSnotifyError()
                // this.$toast.error(this.error);
            }
        },

    },




}
</script>